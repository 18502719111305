<template>
  <div>LoyaltyReportsOwner</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'LoyaltyReportsOwner',
  data() {
    return {}
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loyalty Reports Owner', route: '' },
    ])
  },
  methods: {},
}
</script>
